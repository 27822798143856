// import { nanoid } from 'nanoid';

const headData = {
  title: 'Vlog Monitor',
  lang: 'en',
  siteUrl: 'https://vlog-monitor.adamliu.app/',
  description:
    'Vlog Monitor is audio Vlog Monitor. It supports MP3 and Mp4 files. Designed for learning the foreign language and musical instruments.',
};

const appInfo = {
  title: 'Vlog Monitor',
  subtitle:
    'Vlog Monitor is audio Vlog Monitor. It supports MP3 and Mp4 files. Designed for learning the foreign language and musical instruments.',
  iosLink: 'https://apps.apple.com/us/app/vlogmonitor/id1622988592',
  aosLink: '',
  appIcon: 'vlog_monitor_icon.png',
  appPreview: 'preview_1.png',
  mailTo: 'mailto:adamliu2403+vm@gmail.com?subject=Vlog Monitor',
  email: 'adamliu2403+vm@gmail.com',
};

module.exports = {
  headData,
  appInfo,
};
