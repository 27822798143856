import React, { useState, useEffect } from 'react';
import { WebInfoProvider } from '../context/context';
import { Helmet } from 'react-helmet';

import { headData } from '../data/data';

const mainStyle = {
  width: '100vw',
  height: '100vh',
  backgroundImage: 'linear-gradient(128deg, rgba(255,246,183,1) 0%, rgba(246,65,108,1) 76%)',
};

export default function App({ children }) {
  const { title, lang, description } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Your Title'}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description || 'Your desc'} />
      </Helmet>
      <main style={mainStyle}>{children}</main>
    </>
  );
}
